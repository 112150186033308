import request from './request';
import { environment } from '../enviroments';

const API_PREFIX =  environment.apiPrefix+environment.gwAccount


export var loginSite =function(  params, option?  ){
    return request.post(`${API_PREFIX}/media/none_login/login`, 
       {
        ...params,
       },
     );
   }

export var signSite = function(  params, option?  ){
    return request.post(`${API_PREFIX}/hdt-user-server/users/media/register`, 
       {
        ...params,
       },
     );
   }

export var getMsgCode=function(  params, option?  ){
    return request.post(`${API_PREFIX}/media/none_login/verify_code`, 
       {
        ...params,
       },
     );
   }

export var logOut = function(  params, option?  ){
    return request.get(`${API_PREFIX}/hdt-user-server/logout`, 
        {
            params,
        },
    );
}


export var testCaptcha= function (params?, option?) {
    return new Promise((resolve)=>{
        resolve([
            
        ])
    });
}

export var testPhoneCode=function (params?, option?) {
    return new Promise((resolve)=>{
        resolve([
            
        ])
    });
}

export var getLoginVerifyImg=function(  params, option?  ){
    return request.get(`${API_PREFIX}/media/none_login/captcha`, 
       {
        params,
       },
     );
   }

export var fetchUserInfo=function(params?, option?){
    return request.get(`${API_PREFIX}/todo`, {
        ...option,
        params
    });
}