 const enviroments = {
  "prod-hw":{
    gwCustomer: '',
    apiPrefix: 'https://api-srv.hzjizhun.com',
    gwAccount:'/hdt-portal', //后台
    back:'https://www.hzjizhun.com/hdt-customer/',
    portalUrl:'https://www.hzjizhun.com/hdt-buy-manager/',
    login: 'https://www.jiguang.cn/accounts/login/form',
  },
  "prod-bj":{
    gwCustomer: '',
    apiPrefix: 'https://api-srv.hzjizhun.com',
    gwAccount:'/hdt-portal', //后台
    back:'https://www.hzjizhun.com/hdt-customer/',
    portalUrl:'https://www.hzjizhun.com/hdt-buy-manager/',
    login: 'https://www.jiguang.cn/accounts/login/form',
  },
  prod: {
    gwCustomer: '',
    apiPrefix: 'http://portal.baixuntengfeixinxibe.com',
    gwAccount:'/v4/ad/gw-portal',
    //后台
    back:'http://localhost:3012',
    login: 'https://www.jiguang.cn/accounts/login/form',
  },
  qa: {
    gwCustomer: '',
    apiPrefix: 'http://ad-hdt.glqa.jpushoa.com',
    gwAccount:'/hdt-portal',
    // todo
    portalUrl:'http://ad-hdt.glqa.jpushoa.com/hdt-buy-manager/',
    //后台
    back:'http://ad-hdt.glqa.jpushoa.com/font-hdt-portal/#/',
  },
  dev: {
    gwCustomer: '',
    apiPrefix: 'http://dev-test.jpushoa.com',
    gwAccount:'/ad-test/hdt-portal',
    portalUrl:'http://dev-test.jpushoa.com/ad-test/hdt-buy-manager/',
    //后台
    back:'http://dev-test.jpushoa.com/ad-test/hdt-customer/',
  },
  domainName:{
    gwCustomer: '/ad-interactive-server',
    apiPrefix: 'http://portal.baixuntengfeixinxibe.com',
    gwAccount:'/v4/ad/gw-portal',
    login: 'https://www.jiguang.cn/accounts/login/form',
    logout: 'https://www.jiguang.cn/accounts/logout',
  },
  local: {
    gwCustomer: '',
    apiPrefix: '/dev',      
    gwAccount:'/accountApiDev',
    // portal买量端
    portalUrl:'http://localhost:3013',
    //后台
    back:'http://localhost:3012',
  }
};


console.log(process.env.REACT_APP_ENV);
export const environment = enviroments[process.env.REACT_APP_ENV];