import * as Tools from 'jad-tool'
export const AuthReq = [
  config => {
    // 添加token或者设置cookie
    const token = Tools.getCookie('HToken')
    return {
      ...config,
      headers: {
        Authorization: 'Bearer '+token
      }
    };
  },
  error => {
    return Promise.reject(error);
  }
];
