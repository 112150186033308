import React, { Component } from 'react';
import { Router, Switch, Link } from 'dva/router';
import { isDotCom } from '../../utils/tool.service';
import classnames from 'classnames';

import './DomainName.less';
const isCom = isDotCom();

const domainNameMap = {
    'gmslucky.cn': '浙ICP备19036782号-6',
    'gmlucky.cn': '浙ICP备19036782号-4',
    'gmlky.cn': '浙ICP备19036782号-5',
    'gmslky.cn': '浙ICP备19036782号-3',
    'hpuluck.cn': '浙ICP备19036782号-10',
    'hpulucky.cn': '浙ICP备19036782号',
    'hdtlucky.cn': '浙ICP备19036782号-8',
    'hdtluck.cn': '浙ICP备19036782号-9',
    'abclucky.cn': '浙ICP备19036782号-13',
    'abcluck.cn': '浙ICP备19036782号-11',
    'xyzlucky.cn': '浙ICP备19036782号-14',
    'xyzluck.cn': '浙ICP备19036782号-12',
    'tuilucky.cn': '浙ICP备19036782号-15',
    'tuiluck.cn': '浙ICP备19036782号-16',
    'luckygms.cn': '浙ICP备19036782号-17',
    'luckygm.cn': '浙ICP备19036782号-18',
    'ambinentgm.cn': '浙ICP备19036782号-21',
    'ambinentgms.cn': '浙ICP备19036782号-19',
    'abtgmlk.cn': '浙ICP备19036782号-20',
    'abtgmlky.cn': '浙ICP备19036782号-22 ',
    'abtlucky.cn': '浙ICP备19036782号-23',
    'abtluck.cn': '浙ICP备19036782号-24',
    'abtjz.cn': '浙ICP备19036782号-25',
    'jzabt.cn': '浙ICP备19036782号-26',
    'jzabtgm.cn': '浙ICP备19036782号-29',
    'jzabtgms.cn': '浙ICP备19036782号',
    'abtjzgms.cn': '浙ICP备19036782号-27',
    'abtjzgm.cn': '浙ICP备19036782号-28',
    'jhdtz.cn': '浙ICP备19036782号-31',
    'zhdtj.cn': '浙ICP备19036782号-32',
};

interface state {
    newWebsite: Boolean;
}
export class DomainName extends Component<state> {
    render() {
        const newWebsite = this.props.newWebsite;
        return !newWebsite ? (
            <div className="private-wrap" style={{ maxWidth: '1200px', margin: '0px auto' }}>
                <div className="item">© 极准 使用条款 <a className="link" href="/#/privacy">隐私条款</a></div>
                <div className="item">
                    <span>杭州极准网络科技有限公司</span>
                    <span> 版权所有 © 2019</span>
                    <span>-{new Date().getFullYear()}</span>
                    <a className="link" href="http://beian.miit.gov.cn">
                        {' '}
                        浙ICP备19036782号
                    </a>
                    <p className="ba">
                        <img src="https://img.jiguang.cn/jiguang/img/i-baicon.png" />
                        <a
                            target="_blank"
                            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502005700"
                            className="link"
                        >
                            浙公网安备 33010502005700 号
                        </a>
                    </p>
                </div>
            </div>
        ) : (
            <div className="private-wrap" style={{ maxWidth: '1200px', margin: '0px auto' }}>
                <div className="item">© 矗立 使用条款 隐私</div>
                <div className="item">
                    <span>杭州矗立网络科技有限公司</span>
                    <span> 版权所有 © 2022</span>
                    <span>-{new Date().getFullYear()}</span>
                    <a className="link" href="http://beian.miit.gov.cn">
                        {' '}
                        浙ICP备2022023205号
                    </a>
                    {/* <p className="ba">
                        <img src="https://img.jiguang.cn/jiguang/img/i-baicon.png" />
                        <a
                            target="_blank"
                            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502005700"
                            className="link"
                        >
                            浙公网安备 33010502005700 号
                        </a>
                    </p> */}
                </div>
            </div>
        );
    }
}
