
import React, { Component } from 'react';

import { PageComponent } from 'jad-lib-react';
import { Link } from 'dva/router';
import { connect } from 'dva';
import "./Order.less"
import classnames from 'classnames'
import { Tabs, Input,message } from 'antd';
const TabPane = Tabs.TabPane;
import {API} from '../utils/api'


export class Modal extends Component <PageComponent<{
    onClose:any;
    desc?:any;
}>>{
    render(){
        return <div className="modal-component"  onClick={this.props.onClose}>
                 <div className="mask"></div>
                <div className="modal">
                <i className="close" onClick={this.props.onClose}></i>
                <div className="img-wrap">
                    <img src={require("../assets/path.png")}  className="img" alt=""/>
                </div>
                <p>{this.props.desc ||'预约成功，我们会尽快联系你！'}</p>
            </div>
        </div>
    }
}
export class Order extends Component <PageComponent<{
    toggleVisible:any;
}>>{
    state = {
        tab: 1,
        validtrue: {
            name: true,
            company: true,
            phone: true
        },
        params: {
            name: '',
            company: '',
            phone: ''
        }
    }
    onChangeTab(status) {
        this.setState({
            tab: status
        })
    }

    handleSubmit(){
        let {
            params,
            tab 
        }=this.state;

        if(this.valid()){
            this.props.toggleVisible(false)
            API.postOrderInfo({
                type:tab==1?'media':'advertiser',
                name:params.name,
                companyName:params.company,
                mobile:params.phone
            }).then(res=>{
                if( (res as any).code==2000){
                    this.props.toggleVisible(false)
                }
            },(e)=>{
                message.error(e)
            })
            
        }
        
    }
    valid(toVerifyKey?){
        let { params,validtrue } = this.state;

        let toverify=toVerifyKey?[toVerifyKey]:Object.keys(validtrue)
        toverify.forEach(key=>{
            let val = params[key];
            if(key=='name'){
                if(val.length>8 || val.length==0){
                    validtrue.name=false
                }else{
                    validtrue.name=true
                }
            }
            if(key=='company'){
                if(val.length>8 || val.length==0){
                    validtrue.company=false
                }else{
                    validtrue.company=true
                }
            }
            if(key=='phone'){
                if(/^1[0-9]{10}$/.test(val)){
                    validtrue.phone=true
                }else{
                    validtrue.phone=false
                }
            }
        })
        this.setState({
            validtrue
        })

        return  Object.values(validtrue).every(item=>item);
        
    }
    handleChange(key,val) {
        let {
            params 
        }=this.state;
        params={
            ...params,
            [key]:val
        }
        this.setState({params},()=>{
            this.valid(key);
        })
    }
    render() {
        let { tab,validtrue } = this.state;
        let disabled=Object.values(validtrue).some(item=>!item)
        return (
            <div className="order-component">
                <div className="mask"></div>
                <div className="noblur">
                <div className="order-inner">
                <i className="close-icon" onClick={()=>this.props.toggleVisible(false,true)}></i>
                <div className="dialog-header">
                    <div
                    onClick={()=>{
                        this.setState({
                            tab:1
                        })
                    }}
                    className={classnames("item",{
                        active:tab==1
                    })}>我是广告主</div>
                    <div 
                    onClick={()=>{
                        this.setState({
                            tab:2
                        })
                    }}
                    className={classnames("item",{
                        active:tab==2
                    })}>我是媒体</div>
                </div>
                <section className="dialog-body">
                    <div   className={classnames("field-item",{
                        haserror:!validtrue.name
                    })}>
                        <div className="field-label">
                            姓名
                         <span className="error-tip">姓名长度为1到8位</span>
                        </div>
                        <div>
                            <input
                                placeholder="请输入你的姓名"
                                className="field-value"
                                type="text"
                                onChange={(e) => {
                                    this.handleChange(
                                        'name', e.target.value
                                    )
                                }}></input>
                        </div>
                    </div>
                    <div className={classnames("field-item",{
                        haserror:!validtrue.company
                    })}>
                        <div className="field-label">
                        企业名称
                         <span className="error-tip"> 企业名称长度为1到8位</span>
                        </div>
                        <div>
                            <input
                                placeholder="若为个人机构，请填写个人名称"
                                className="field-value"
                                type="text"
                                onChange={(e) => {
                                    this.handleChange(
                                        'company', e.target.value
                                    )
                                }}></input>
                        </div>
                    </div>
                    <div  className={classnames("field-item",{
                        haserror:!validtrue.phone
                    })}>
                        <div className="field-label">
                        联系电话
                         <span className="error-tip">手机号格式不正确</span>
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="请输入你的手机号码"
                                className="field-value"
                                onChange={(e) => {
                                    this.handleChange(
                                        'phone', e.target.value
                                    )
                                }}></input>
                        </div>
                    </div>
                   <div   className={classnames("dialog-btn",{
                        disabled:disabled
                    })} onClick={()=>{
                       
                       if(disabled){
                           return ;
                       }
                       this.handleSubmit()
                   }}>立即预约</div>
                </section>
                </div>
                </div>
                
            </div>
        )
    }
}