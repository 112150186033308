import axios from 'axios';
import { ErrInterceport } from './httpInterceptors/err';
import { CookieAuthInterceport } from './httpInterceptors/cookieAuth';
import { AuthReq } from './httpInterceptors/tokenAuth';
import { TransformDataInterceport } from './httpInterceptors/transformData';


axios.interceptors.request.use(...CookieAuthInterceport.req);
axios.interceptors.request.use(...AuthReq);

axios.interceptors.response.use(...ErrInterceport.res);
axios.interceptors.response.use(...TransformDataInterceport.res);

axios.defaults.withCredentials = true;

export default axios;
