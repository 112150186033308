import request from './request';

import { environment } from '../enviroments';
import * as _authAPI from './auth.api'

const API_PREFIX = environment.apiPrefix;
const GW=API_PREFIX+environment.gwAccount;
const PREFIX=GW+environment.gwCustomer;


export const authAPI =_authAPI

export let  API  = {
  fetchMenu(){
    return []
  },
  postOrderInfo:function(  params, option?  ){
    return request.post(`${PREFIX}/hdt-user-server/appointments`, 
       {
        ...params,
       },
     );
   },
  getUserInfo:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/login-account-info`, 
       {
        params,
       },
     );
   },
  logout:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/logout`, 
       {
        params,
       },
     );
   },
  loginSite:function(  params, option?  ){
    return request.post(`${PREFIX}/hdt-user-server/login`, 
       {
        ...params,
       },
     );
   },
   userRoles:function(  params, option?  ){
    return request.post(`${PREFIX}/hdt-user-server/user-roles`, 
       {
        ...params,
       },
     );
   },
   userBindedAdvertisingIds:function(){
    return request.get(`${PREFIX}/hdt-agency-server/advertising-customer/user-advertising-ids`,
       {},
     );
   },
  fetchFakeData(params,options?){
    return {

    }
  }, 
  testPhoneCode:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/common/check-sms-code`, 
       {
        params,
       },
     );
   },
  getMsgCode:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/common/generate-sms-code`, 
       {
        params,
       },
     );
   },
  signSite(  params, option?  ){
    return request.post(`${PREFIX}/hdt-user-server/users/register`, 
       {
        ...params,
       },
     );
   }, 
  getApplyInfo:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-agency-server/agency-customer/users/agencies-apply`, 
       {
        params,
       },
     );
   },
  testCaptcha:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/common/check-verify-code`, 
       {
        params,
       },
     );
   },
  getLoginVerifyImg:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/common/generate-verify-code`, 
       {
        params,
       },
     );
   },
   putAgencies:function( agencyId, params, option?  ){
    return request.get(`${PREFIX}/hdt-agency-server/agency-customer/agencies/${agencyId}`, 
       {
        params,
       },
     );
   },
   postAgencies:function(  params, option?  ){
    return request.post(`${PREFIX}/hdt-agency-server/agency-customer/agencies`, 
       {
        ...params,
       },
     );
   },

  getCDNToken:function(  params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/qiniu/token`, 
       {
        params,
       },
     );
   },
  getForgetMsgCode:function(  platform,params, option?  ){
    return request.get(`${PREFIX}/hdt-user-server/common/${platform}/email/generate-sms-code`, 
       {
        params,
       },
     );
   },
  postForgetPwd:function( platform, params, option?  ){
    return request.post(`${PREFIX}/hdt-user-server/password/${platform}/forget`, 
       {
        ...params,
       },
     );
   }
  
}
